// import menuImage from '../assets/image/menu-image.webp'

export const menuItems = [
         {
           name: "",
           label: "Inicio",
         },
         {
           name: "pages",
           label: "Productos",
           items: [
             { name: "innerpage/about-us", label: "ValuaBot", name:"valuaBot" },
             { name: "innerpage/service", label: "Control de empeños y compras", name:"loansPurchases" },
             { name: "innerpage/service", label: "Sistema de cobranza", name:"sistemaCobranza" },
             { name: "innerpage/service", label: "Facturación", name:"facturacion" },
             { name: "innerpage/service", label: "Gestor de inventario", name:"inventario" },
             { name: "innerpage/service", label: "Canales de venta", name:"canales" },
           ],
         },
         {
           name: "megamenu",
           label: "Precios",
           name: "precios"
          },
         {
          label: "Recursos",
           items: [
             {
               name: "centroAyuda",
               label: "Centro de ayuda",
             },
             {
               label: "Capacitación",
               items: [
                  {
                    name: "capacitacion/pruebas-senuelo",
                    label: "Pruebas Señuelo",
                  },
                  {
                    name: "capacitacion/secciones",
                    label: "Secciones",
                  },
                  /*{
                    name: "capacitacion/streaming",
                    label: "Streaming",
                  },*/
               ]
             },
             {
               name: "contacto",
               label: "Centro de contacto",
             },
           ], 
        },
       ];
export default menuItems;